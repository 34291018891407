<template>
	<section class="section">
		<div class="container">
			<div class="columns is-centered has-text-centered">
				<div class="column is-4">
					<div class="hit-icon">
						<img src="@/assets/img/invite_ru/icon_markets.svg" />
					</div>
					<h3 class="title is-5" v-html="$t('privet.FEATURE_H1')" />
					<p
						class="subtitle subtitle-spaced is-size-6"
						v-html="$t('privet.FEATURE_P1')"
					/>
				</div>
				<div class="column is-1 is-hidden-tablet" />
				<div class="column is-4">
					<div class="hit-icon">
						<img src="@/assets/img/invite_ru/icon_grow.svg" />
					</div>
					<h3 class="title is-5" v-html="$t('privet.FEATURE_H2')" />
					<p
						class="subtitle subtitle-spaced is-size-6"
						v-html="$t('privet.FEATURE_P2')"
					/>
				</div>
				<div class="column is-1 is-hidden-tablet" />
				<div class="column is-4">
					<div class="hit-icon">
						<img src="@/assets/img/invite_ru/icon_fast.svg" />
					</div>
					<h3 class="title is-5" v-html="$t('privet.FEATURE_H3')" />
					<p
						class="subtitle subtitle-spaced is-size-6"
						v-html="$t('privet.FEATURE_P3')"
					/>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: "Features",
};
</script>

<style scoped>
.section {
	padding-top: 80px;
	padding-bottom: 80px;
}
.hit-cta .title {
	margin-bottom: 0;
}
.hit-icon {
	margin-bottom: 10px;
}
.faq {
	margin-top: 2rem;
}
</style>
