<template>
	<section class="hero is-small">
		<Navbrand />
		<div class="hero-body-custom">
			<div class="container">
				<div class="columns is-vcentered">
					<div class="column is-7-tablet is-6-desktop is-5-widescreen">
						<h1
							class="title is-size-1 title-font"
							v-html="$t('privet.HEADER')"
						/>
						<div class="column is-paddingless">
							<p class="subtitle is-size-4" v-html="inviteMessage ? inviteMessage : $t('privet.SUBTITLE')" />
						</div>
						<div class="column pl-0 pt-20">
							<GetStartedButton
								:customInvite="customInvite"
								:customText="$t('privet.BUTTON')"
								:userAlreadyLoggedIn="isUserLoggedInTradingApp()"
							/>
						</div>
						<!-- Quick Info Callouts -->
						<div class="quick-info is-size-14 has-text-weight-medium">
							<div class="mini-tag">
								<b-icon
									icon="far fa-check-circle"
									pack="far"
									class="check-icon"
								/>
								<p v-html="$t('privet.QUICK_1')" />
							</div>
							<div class="mini-tag">
								<b-icon
									icon="far fa-check-circle"
									pack="far"
									class="check-icon"
								/>
								<p v-html="$t('privet.QUICK_2')" />
							</div>
							<div class="mini-tag">
								<b-icon
									icon="far fa-check-circle"
									pack="far"
									class="check-icon"
								/>
								<p v-html="$t('privet.QUICK_3')" />
							</div>
						</div>
					</div>
					<div class="column">
						<figure class="airdrop-image">
							<picture>
								<source
									type="image/png"
									srcset="@/assets/img/invite_ru/market_tiles.png"
								/>
								<img
									src="@/assets/img/invite_ru/market_tiles.png"
									:alt="$t('privet.HERO_ALT')"
								/>
							</picture>
						</figure>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import Navbrand from "@/components/partials/Navbrand";
import GetStartedButton from "@/components/partials/GetStartedButton";
import { isUserLoggedInTradingApp } from "@/utils/helpers";

export default {
	name: "Hero",
	components: {
		Navbrand,
		GetStartedButton,
	},
	props: {
		customInvite: {
			type: String,
			required: false,
			default: "",
		},
		inviteMessage: {
			type: String,
			required: false,
			default: "",
		},
		airdropAmount: {
			type: Number,
			required: true,
			default: 0,
		},
		referralBonus: {
			type: Number,
			required: false,
			default: 0,
		},
	},
	methods: {
		isUserLoggedInTradingApp,
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.quick-info {
	margin-top: -84px;
	display: flex;
	flex-wrap: wrap;
}
.quick-info .mini-tag {
	display: flex;
	align-items: center;
	margin-right: 15px;
	margin-top: 0.25rem;
}
.check-icon {
	font-size: 12px;
}
.hero {
	background-color: #d8ede3;
}
.hero .subtitle {
	line-height: 1.5;
}
.pt-40 {
	padding-top: 40px;
}
.pt-5 {
	padding-top: 5px;
}
.mt-20-tablet {
	margin-top: 20px;
}

.mt-55-tablet {
	margin-top: 30px;
}

.centered {
	display: flex;
	justify-content: center;
	align-items: center;
}

.spacing-featured {
	margin-top: 40px;
}

.airdrop-image {
	padding-left: 50px;
	margin-right: -100px;
	margin-top: -120px;
	max-height: 650px;
	max-width: 745px;
}

@media only screen and (max-width: 1215px) {
	.hero-body-custom {
		padding-bottom: 1rem;
	}
}
@media only screen and (max-width: 1023px) {
	.is-center-touch {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.airdrop-image {
		margin-top: -60px;
	}
	.hero-body-custom {
		padding: 2rem 1.5rem;
	}
}
@media only screen and (max-width: 768px) {
	.is-center-mobile {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.hero.hero.is-fullheight {
		min-height: initial;
	}
	.airdrop-image {
		margin-top: -20px;
		max-height: 590px;
	}
}
@media only screen and (max-width: 500px) {
	.hero .title {
		margin-top: -20px;
	}
	.market-mobile-hide {
		display: none;
	}
	.text-left-mobile {
		text-align: left !important;
	}
	.mt-55-tablet {
		margin-top: inherit;
	}
	.mt-20-tablet {
		margin-top: inherit;
	}
	.spacing-featured {
		margin-top: -40px;
	}
	.airdrop-image {
		margin-top: -40px;
		max-height: 370px;
	}
}
@media only screen and (min-height: 1200px) {
	.hero.is-fullheight {
		min-height: 1200px;
	}
}

.pt-0 {
	padding-top: 0px;
	margin-top: -100px;
}
.pl-0 {
	padding-left: 0px;
}
.pt-35 {
	padding-top: 35px;
}
.mt-10 {
	margin-top: 10px;
}
.is-voffset {
	margin-bottom: -180px;
}
.is-26 {
	max-height: 26px;
}
.input-button {
	border-radius: 5px;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
	border: none;
	background: #00c386;
	font-family: "Roboto", sans-serif;
	font-weight: 600 !important;
	color: #fff;
	transition-delay: 0s;
	transition-duration: 0.5s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
	outline: none;
	width: 100%;
}

.input-button:hover {
	cursor: pointer;
	outline: none;
	background: #00d492;
	color: #fff;
	box-shadow: 0px 1px 2px rgba(0, 161, 111, 0.2),
		0px 6px 12px rgba(0, 161, 111, 0.2);
	transform: translateY(0);
	transition-delay: 0s;
	transition-duration: 0.5s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
</style>
